import { Component, Input, OnInit } from '@angular/core';
import {
  getCityWithCantonAbbrevation,
  getPublicFileUrl,
} from '../../../../core/helpers';
import { Job } from '../../../../types/job';
import { JobService } from 'src/app/services/job.service';
import { EMPTY, Observable } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-job-header',
  templateUrl: './job-header.component.html',
  styleUrls: ['./job-header.component.scss'],
})
export class JobHeaderComponent implements OnInit {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  isAuthorized$: Observable<boolean> = EMPTY;
  hasAlreadyApplied = false;

  constructor(
    private jobService: JobService,
    profileService: ProfileService,
    private applicationService: ApplicationService
  ) {
    this.isAuthorized$ = profileService.isAuthorized();
  }

  ngOnInit() {
    this.applicationService
      .hasAlreadyApplied(this.job.id!)
      .subscribe(applied => {
        this.hasAlreadyApplied = applied;
      });
  }

  getAvatarUrl(job: Job) {
    return getPublicFileUrl('schools', job.schoolId, 'avatar');
  }

  getBackgroundUrl(job: Job) {
    return getPublicFileUrl('schools', job.schoolId, 'backgroundImage');
  }

  getSchoolNameAndLocation(job: Job) {
    return `${job.schoolName}, ${getCityWithCantonAbbrevation(job.schoolAddress)}`;
  }

  getAddress(job: Job) {
    return job.schoolAddress?.city === job.schoolAddress?.municipality
      ? job.schoolAddress?.city
      : `${job.schoolAddress?.city}, ${job.schoolAddress?.municipality}`;
  }

  hasSchoolLevels(job: Job) {
    return !!(job.schoolLevels && job.schoolLevels.length > 0);
  }

  getJobLevels(job: Job) {
    return this.jobService.getLevelsTitle(
      job.schoolLevels || [],
      job.schoolLevelDescriptions || []
    );
  }

  getRequirementsText(job: Job) {
    let requirement = '';

    switch (true) {
      case job.requirements?.diplom:
        requirement = 'Diplom';
        break;
      case job.requirements?.baseYear:
        requirement = 'Basisjahr abgeschlossen';
        break;
      default:
        requirement = 'In Ausbildung';
        break;
    }

    return `Mindestqualifikation: ${requirement}`;
  }

  getScheduleText(job: Job) {
    return this.jobService.getScheduleText(job);
  }

  hasSubjects(job: Job) {
    return !!(job.subjects && job.subjects.length > 0);
  }

  hasRequirements(job: Job) {
    if (!job.requirements) {
      return false;
    }

    return job.requirements.diplom || job.requirements.student;
  }

  getSubstitutionPeriodText(job: Job) {
    return this.jobService.getSubstitutionPeriodText(job);
  }

  showApplyButton(isAuthorized: boolean | null) {
    if (this.isEditable) {
      return false;
    }

    if (isAuthorized) {
      return !this.hasAlreadyApplied;
    }

    return this.job.isPublic && !this.job.isClosed;
  }

  getApplyLink(isAuthorized: boolean | null) {
    return isAuthorized ? 'apply' : 'apply/public';
  }
}
