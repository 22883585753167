import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { PermissionsService } from '../../../services/permissions.service';
import { SchoolService } from '../../../services/school.service';
import { School } from '../../../types/school';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-school-profile',
  templateUrl: './school-profile.component.html',
  styleUrls: ['./school-profile.component.scss'],
})
export class SchoolProfileComponent {
  id?: string | null;
  school$?: Observable<School>;
  fetch$ = new ReplaySubject(1);

  isOwner = false;
  isPreview = false;

  constructor(
    route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private schoolService: SchoolService,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showBoth: true });

    route.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.school$ = this.fetch$.pipe(
        switchMap(() =>
          this.schoolService.getById(this.id!).pipe(
            tap(school => {
              this.isOwner = this.permissionsService.isOwner(school.rootId);
            })
          )
        )
      );

      this.reload();
    });

    route.queryParamMap.subscribe(params => {
      this.isPreview = !!params.get('preview');
    });
  }

  get isEditable() {
    return this.isOwner && !this.isPreview;
  }

  reload() {
    this.fetch$.next(1);
  }
}
