<article class="contact-form-item">
  <aside class="contact-form-item__avatar">
    @if (contact().isAdmin) {
      <app-round-image
        [src]="getPublicFileUrl('profiles', contact().userId, 'avatar')" />
    } @else {
      <input
        type="file"
        hidden
        [accept]="acceptTypes"
        (change)="openDialog($event)"
        #file />
      <app-round-image
        class="cursor-pointer"
        [src]="base64Avatar || contact().avatarUrl"
        (click)="file.click()" />
    }
  </aside>
  <aside class="contact-form-item__sidebar">
    <mat-checkbox
      class="contact-form-item__sidebar__checkbox"
      [checked]="contact().visible"
      (change)="onChanged({ visible: active.checked })"
      #active />
    @if (!contact().isAdmin) {
      <button mat-icon-button (click)="onDelete()">
        <mat-icon class="material-symbols-rounded">delete</mat-icon>
      </button>
    }
  </aside>
  <form
    class="contact-form-item__content"
    novalidate
    [formGroup]="contact().form">
    @if (!contact().isAdmin) {
      <mat-form-field class="w-full">
        <mat-label>Vorname, Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Gib einen Vornamen und Namen an.</mat-error>
      </mat-form-field>
    } @else {
      <input matInput hidden formControlName="name" />
      <strong class="contact-form-item__content__name">
        {{ contact().form.value.name }}
      </strong>
    }
    <mat-form-field class="w-full">
      <mat-label>Berufsbezeichnung</mat-label>
      <input matInput formControlName="jobFunction" />
      <mat-error>Gib eine Berufsbezeichnung an.</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Telefonnummer</mat-label>
      <input matInput formControlName="phone" />
      <mat-error>Gib eine Telefonnummer an.</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>E-Mail</mat-label>
      <input matInput formControlName="email" />
      <mat-error>Gib eine E-Mail an.</mat-error>
    </mat-form-field>
  </form>
</article>
