import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  MatRippleModule,
} from '@angular/material/core';
import { InputErrorStateMatcher } from './core/InputErrorStateMatcher';
import { LoginComponent } from './pages/login/login.component';
import { SchoolsComponent } from './pages/schools/schools.component';
import { SignupComponent } from './pages/signup/signup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SchoolVerificationComponent } from './pages/register-school/school-verification/school-verification.component';
import { HomeComponent } from './pages/home/home.component';
import { PermissionsService } from './services/permissions.service';
import { SchoolAboutFormComponent } from './pages/schools/forms/school-about-form/school-about-form.component';
import { SchoolFocusFormComponent } from './pages/schools/forms/school-focus-form/school-focus-form.component';
import { SchoolMediaFormComponent } from './pages/schools/forms/school-media-form/school-media-form.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileDetailComponent } from './pages/profiles/profile-detail/profile-detail.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { ProfileWizardComponent } from './pages/profiles/profile-wizard/profile-wizard.component';
import { SchoolProfileComponent } from './pages/schools/school-profile/school-profile.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SchoolPermissionsFormComponent } from './pages/schools/forms/school-permissions-form/school-permissions-form.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LocationsService } from './services/locations.service';
import { JobsComponent } from './pages/jobs/jobs.component';
import { JobDetailComponent } from './pages/jobs/job-detail/job-detail.component';
import { JobDateFormComponent } from './pages/jobs/forms/job-date-form/job-date-form.component';
import { JobRequirementsFormComponent } from './pages/jobs/forms/job-requirements-form/job-requirements-form.component';
import { JobSchoolLevelsFormComponent } from './pages/jobs/forms/job-school-levels-form/job-school-levels-form.component';
import { JobSkillsFormComponent } from './pages/jobs/forms/job-skills-form/job-skills-form.component';
import { JobSubjectsFormComponent } from './pages/jobs/forms/job-subjects-form/job-subjects-form.component';
import { JobTasksFormComponent } from './pages/jobs/forms/job-tasks-form/job-tasks-form.component';
import { JobWeOfferFormComponent } from './pages/jobs/forms/job-we-offer-form/job-we-offer-form.component';
import { JobWorkloadFormComponent } from './pages/jobs/forms/job-workload-form/job-workload-form.component';
import { JobCreateComponent } from './pages/jobs/job-create/job-create.component';
import { MatRadioModule } from '@angular/material/radio';
import { JobService } from './services/job.service';
import { SchoolService } from './services/school.service';
import { ProfileService } from './services/profile.service';
import { JobFunctionFormComponent } from './pages/jobs/forms/job-function-form/job-function-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WorkloadMatrixComponent } from './components/form-controls/workload-matrix/workload-matrix.component';
import { de } from 'date-fns/locale';
import { SortbyPipe } from './core/sortby.pipe';
import { firebaseProviders } from './config/firebase.providers';
import { ProfileAboutFormComponent } from './pages/profiles/forms/profile-about-form/profile-about-form.component';
import { ProfileAvatarFormComponent } from './pages/profiles/forms/profile-avatar-form/profile-avatar-form.component';
import { ProfileBackgroundFormComponent } from './pages/profiles/forms/profile-background-form/profile-background-form.component';
import { ProfileCertificationsFormComponent } from './pages/profiles/forms/profile-certifications-form/profile-certifications-form.component';
import { ProfileEducationFormComponent } from './pages/profiles/forms/profile-education-form/profile-education-form.component';
import { ProfileJobFormComponent } from './pages/profiles/forms/profile-job-form/profile-job-form.component';
import { ProfilePreferencesFormComponent } from './pages/profiles/forms/profile-preferences-form/profile-preferences-form.component';
import { ProfileSloganFormComponent } from './pages/profiles/forms/profile-slogan-form/profile-slogan-form.component';
import { ProfileSubjectsFormComponent } from './pages/profiles/forms/profile-subjects-form/profile-subjects-form.component';
import { ProfileWorkExperienceFormComponent } from './pages/profiles/forms/profile-work-experience-form/profile-work-experience-form.component';
import { ProfileLanguagesFormComponent } from './pages/profiles/forms/profile-languages-form/profile-languages-form.component';
import { MatChipsModule } from '@angular/material/chips';
import { SchoolAvatarFormComponent } from './pages/schools/forms/school-avatar-form/school-avatar-form.component';
import { SchoolBackgroundFormComponent } from './pages/schools/forms/school-background-form/school-background-form.component';
import { YearMonthFormatDirective } from './directives/year-month-format.directive';
import { ApplicationDetailComponent } from './pages/applications/application-detail/application-detail.component';
import { MyApplicationsComponent } from './pages/applications/my-applications/my-applications.component';
import { JobApplyComponent } from './pages/jobs/job-apply/job-apply.component';
import { ApplicationService } from './services/application.service';
import { FileService } from './services/files.service';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionIconButtonComponent } from './components/action-icon-button/action-icon-button.component';
import { SchoolsMyComponent } from './pages/schools/schools-my/schools-my.component';
import { SchoolsJobsComponent } from './pages/schools/schools-jobs/schools-jobs.component';
import { SchoolsJobsApplicantsComponent } from './pages/schools/schools-jobs-applicants/schools-jobs-applicants.component';
import { JobCloseDialogComponent } from './pages/jobs/job-close-dialog/job-close-dialog.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsDeleteAccountComponent } from './pages/settings/settings-delete-account/settings-delete-account.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbService } from './services/breadcrumb.service';
import { SettingsChangeEmailComponent } from './pages/settings/settings-change-email/settings-change-email.component';
import { SettingsChangeNameComponent } from './pages/settings/settings-change-name/settings-change-name.component';
import { SettingsChangePasswordSecureHelpComponent } from './pages/settings/settings-change-password-secure-help/settings-change-password-secure-help.component';
import { SettingsChangePasswordComponent } from './pages/settings/settings-change-password/settings-change-password.component';
import { SettingsContactComponent } from './pages/settings/settings-contact/settings-contact.component';
import { SettingsMarketingComponent } from './pages/settings/settings-marketing/settings-marketing.component';
import { SettingsPrivacyComponent } from './pages/settings/settings-privacy/settings-privacy.component';
import { SettingService } from './services/setting.service';
import { SettingsAccountComponent } from './pages/settings/settings-account/settings-account.component';
import { NotificationService } from './services/notification.service';
import { SettingsPasswordResetConfirmDialogComponent } from './pages/settings/settings-password-reset-confirm-dialog/settings-password-reset-confirm-dialog.component';
import { SettingsEmailChangeConfirmDialogComponent } from './pages/settings/settings-email-change-confirm-dialog/settings-email-change-confirm-dialog.component';
import { PasswordForgetComponent } from './pages/login/password-forget/password-forget.component';
import { PasswordForgetSuccessDialogComponent } from './pages/login/password-forget-success-dialog/password-forget-success-dialog.component';
import { SettingsChangeNameInfoComponent } from './pages/settings/settings-change-name-info/settings-change-name-info.component';
import { SupportService } from './services/support.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProfileCardComponent } from './pages/profiles/components/profile-card/profile-card.component';
import { TagComponent } from './components/tag/tag.component';
import { SortPipe } from './core/sort.pipe';
import { SchoolCardComponent } from './components/school-card/school-card.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { SchoolTypeTagComponent } from './components/school-type-tag/school-type-tag.component';
import { JobCardComponent } from './components/job-card/job-card.component';
import { ProfileHeaderComponent } from './pages/profiles/components/profile-header/profile-header.component';
import { ProfileProgressCardComponent } from './pages/profiles/components/profile-progress-card/profile-progress-card.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressCardItemComponent } from './components/progress-card-item/progress-card-item.component';
import { EmptyCardComponent } from './components/empty-card/empty-card.component';
import { ProfileQualificationCardComponent } from './pages/profiles/components/profile-qualification-card/profile-qualification-card.component';
import { ProfileAboutMeCardComponent } from './pages/profiles/components/profile-about-me-card/profile-about-me-card.component';
import { ProfilePreferencesCardComponent } from './pages/profiles/components/profile-preferences-card/profile-preferences-card.component';
import { ProfileWorkExperiencsCardComponent } from './pages/profiles/components/profile-work-experiencs-card/profile-work-experiencs-card.component';
import { ProfileEducationCardComponent } from './pages/profiles/components/profile-education-card/profile-education-card.component';
import { ProfileLanguageCardComponent } from './pages/profiles/components/profile-language-card/profile-language-card.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { SchoolHeaderComponent } from './pages/schools/components/school-header/school-header.component';
import { SchoolNameFormComponent } from './pages/schools/forms/school-name-form/school-name-form.component';
import { SchoolProgressCardComponent } from './pages/schools/components/school-progress-card/school-progress-card.component';
import { SchoolInfoFormComponent } from './pages/schools/forms/school-info-form/school-info-form.component';
import { SchoolDocumentsFormComponent } from './pages/schools/forms/school-documents-form/school-documents-form.component';
import { SchoolMediaCardComponent } from './pages/schools/components/school-media-card/school-media-card.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SchoolAboutCardComponent } from './pages/schools/components/school-about-card/school-about-card.component';
import { SchoolInfoCardComponent } from './pages/schools/components/school-info-card/school-info-card.component';
import { SchoolIntroCardComponent } from './pages/schools/components/school-intro-card/school-intro-card.component';
import { SchoolDocumentsCardComponent } from './pages/schools/components/school-documents-card/school-documents-card.component';
import { SchoolFocusCardComponent } from './pages/schools/components/school-focus-card/school-focus-card.component';
import { SchoolWeofferCardComponent } from './pages/schools/components/school-weoffer-card/school-weoffer-card.component';
import { SchoolWeofferFormComponent } from './pages/schools/forms/school-weoffer-form/school-weoffer-form.component';
import { SchoolContactCardComponent } from './pages/schools/components/school-contact-card/school-contact-card.component';
import { SchoolJobsCardComponent } from './pages/schools/components/school-jobs-card/school-jobs-card.component';
import { CUSTOM_ICONS, getIconName } from './config/custom-icons';
import { BackButtonDirective } from './directives/back-button.directive';
import { JobHeaderComponent } from './pages/jobs/components/job-header/job-header.component';
import { JobItemFieldComponent } from './pages/jobs/components/job-item-field/job-item-field.component';
import { JobDurationFormComponent } from './pages/jobs/forms/job-duration-form/job-duration-form.component';
import { JobApplicationDeadlineFormComponent } from './pages/jobs/forms/job-application-deadline-form/job-application-deadline-form.component';
import { JobWorkloadMatrixFormComponent } from './pages/jobs/forms/job-workload-matrix-form/job-workload-matrix-form.component';
import { JobWeOfferCardComponent } from './pages/jobs/components/job-we-offer-card/job-we-offer-card.component';
import { JobTasksCardComponent } from './pages/jobs/components/job-tasks-card/job-tasks-card.component';
import { JobSkillsCardComponent } from './pages/jobs/components/job-skills-card/job-skills-card.component';
import { ApplicationCardComponent } from './components/application-card/application-card.component';
import { ApplicationRowComponent } from './pages/schools/components/application-row/application-row.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { SchoolOnboardingComponent } from './pages/schools/school-onboarding/school-onboarding.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FooterComponent } from './components/footer/footer.component';
import { PublicToolbarComponent } from './components/public-toolbar/public-toolbar.component';
import { SignupSecurePasswordComponent } from './pages/signup/signup-secure-password/signup-secure-password.component';
import { GoodbyeComponent } from './pages/goodbye/goodbye.component';
import { JobApplyPublicComponent } from './pages/jobs/job-apply-public/job-apply-public.component';
import { ReversePipe } from './core/reverse.pipe';
import { JobProgressCardComponent } from './pages/jobs/components/job-progress-card/job-progress-card.component';
import { PublicToolbarService } from './services/public-toolbar.service';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { CONFIG } from '@angular/fire/compat/analytics';
import { EmptyBoxComponent } from './components/empty-box/empty-box.component';
import { ApiVideoService } from './services/api-video.service';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { RejectMessageDialogComponent } from './pages/applications/reject-message-dialog/reject-message-dialog.component';
import { WelcomeSchoolCardComponent } from './pages/home/welcome-school-card/welcome-school-card.component';
import { SecurePasswordHelpComponent } from './components/secure-password-help/secure-password-help.component';
import { PasswordFieldsComponent } from './components/form-controls/password-fields/password-fields.component';
import { OrderProcessComponent } from './dialogs/order-process/order-process.component';
import { OrderProcessStartComponent } from './dialogs/order-process/order-process-start/order-process-start.component';
import { OrderProcessProductsComponent } from './dialogs/order-process/order-process-products/order-process-products.component';
import { OrderProcessOrderingComponent } from './dialogs/order-process/order-process-ordering/order-process-ordering.component';
import { OrderingAddressComponent } from './dialogs/order-process/components/ordering-address/ordering-address.component';
import { OrderingOverviewComponent } from './dialogs/order-process/components/ordering-overview/ordering-overview.component';
import { OrderingConfirmationComponent } from './dialogs/order-process/components/ordering-confirmation/ordering-confirmation.component';
import { OrderProcessBenefitComponent } from './dialogs/order-process/order-process-start/order-process-benefit/order-process-benefit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { NotificationSubscriptionService } from './services/notification-subscription.service';
import { SearchAgentComponent } from './pages/search-agent/search-agent.component';
import { HighlightBubbleComponent } from './components/highlight-bubble/highlight-bubble.component';
import { NgxEditorModule } from 'ngx-editor';
import { ngxEditorConfiguration } from './config/ngx-editor.settings';
import { RichTextFieldComponent } from './components/form-controls/rich-text-field/rich-text-field.component';
import { RichTextViewComponent } from './components/rich-text-view/rich-text-view.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { EdulogCallbackComponent } from './pages/auth/edulog-callback.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SchoolsMyProgressCardComponent } from './pages/schools/schools-my/schools-my-progress-card/schools-my-progress-card.component';
import { SchoolMyItemComponent } from './pages/schools/schools-my/school-my-item/school-my-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../modules/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { HrJobCardComponent } from './pages/schools/components/hr-job-card/hr-job-card.component';
import { HighlightListSelectionComponent } from './pages/schools/components/highlight-list-selection/highlight-list-selection.component';
import { HighlightItemListComponent } from './pages/schools/components/highlight-item-list/highlight-item-list.component';
import { JobTypeChipComponent } from './pages/jobs/components/job-type-chip/job-type-chip.component';
import { PassedTimeInfoComponent } from './components/passed-time-info/passed-time-info.component';
import { SchoolHeaderLocationsComponent } from './pages/schools/components/school-header-locations/school-header-locations.component';
import { JobTopBarComponent } from './pages/jobs/components/job-top-bar/job-top-bar.component';
import { JobShareButtonComponent } from './pages/jobs/components/job-share-button/job-share-button.component';
import { JobMediaCardComponent } from './pages/jobs/components/job-media-card/job-media-card.component';
import { JobMediaFormComponent } from './pages/jobs/forms/job-media-form/job-media-form.component';
import { JobIntroCardComponent } from './pages/jobs/components/job-intro-card/job-intro-card.component';
import { MediaFormCropperComponent } from './components/media-form-cropper/media-form-cropper.component';
import { JobAdditionalInfosCardComponent } from './pages/jobs/components/job-add-infos-card/job-add-infos-card.component';
import { JobAdditionalInfosFormComponent } from './pages/jobs/forms/job-add-infos-form/job-add-infos-form.component';
import { JobContactCardComponent } from './pages/jobs/components/job-contact-card/job-contact-card.component';
import { JobContactFormComponent } from './pages/jobs/forms/job-contact-form/job-contact-form.component';
import { JobContactFormItemComponent } from './pages/jobs/forms/job-contact-form/components/job-contact-form-item/job-contact-form-item.component';
import { JobContactFormDialogComponent } from './pages/jobs/forms/job-contact-form/components/job-contact-form-dialog/job-contact-form-dialog.component';
import { JobContactCardItemComponent } from './pages/jobs/components/job-contact-card/components/job-contact-card-item/job-contact-card-item.component';
import { SchoolShareButtonComponent } from './pages/schools/components/school-share-button/school-share-button.component';
import { SchoolTopBarComponent } from './pages/schools/components/school-top-bar/school-top-bar.component';
import { TopBarItemComponent } from './components/top-bar/top-bar-item/top-bar-item.component';
import { TopBarPublishComponent } from './components/top-bar/top-bar-publish/top-bar-publish.component';
import { TopBarPreviewComponent } from './components/top-bar/top-bar-preview/top-bar-preview.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    SchoolsComponent,
    SchoolVerificationComponent,
    SchoolAboutFormComponent,
    SchoolAvatarFormComponent,
    SchoolBackgroundFormComponent,
    SchoolFocusFormComponent,
    SchoolMediaFormComponent,
    ProfilesComponent,
    ProfileDetailComponent,
    ProfileWizardComponent,
    SchoolProfileComponent,
    SchoolPermissionsFormComponent,
    JobsComponent,
    JobDetailComponent,
    JobSchoolLevelsFormComponent,
    JobSubjectsFormComponent,
    JobRequirementsFormComponent,
    JobDateFormComponent,
    JobWorkloadFormComponent,
    JobWeOfferFormComponent,
    JobTasksFormComponent,
    JobSkillsFormComponent,
    JobCreateComponent,
    JobFunctionFormComponent,
    WorkloadMatrixComponent,
    SortbyPipe,
    SortPipe,
    ReversePipe,
    ProfileAvatarFormComponent,
    ProfileBackgroundFormComponent,
    ProfileJobFormComponent,
    ProfileSloganFormComponent,
    ProfileAboutFormComponent,
    ProfileSubjectsFormComponent,
    ProfileCertificationsFormComponent,
    ProfileWorkExperienceFormComponent,
    ProfileEducationFormComponent,
    ProfilePreferencesFormComponent,
    ProfileLanguagesFormComponent,
    YearMonthFormatDirective,
    BackButtonDirective,
    MyApplicationsComponent,
    ApplicationDetailComponent,
    JobApplyComponent,
    ActionButtonComponent,
    ActionIconButtonComponent,
    SchoolsMyComponent,
    SchoolsJobsComponent,
    SchoolsJobsApplicantsComponent,
    JobCloseDialogComponent,
    SettingsComponent,
    SettingsDeleteAccountComponent,
    BreadcrumbsComponent,
    SettingsAccountComponent,
    SettingsContactComponent,
    SettingsChangeEmailComponent,
    SettingsChangePasswordComponent,
    SettingsChangePasswordSecureHelpComponent,
    SettingsChangeNameComponent,
    SettingsPrivacyComponent,
    SettingsMarketingComponent,
    SettingsPasswordResetConfirmDialogComponent,
    SettingsEmailChangeConfirmDialogComponent,
    PasswordForgetComponent,
    PasswordForgetSuccessDialogComponent,
    SettingsChangeNameInfoComponent,
    ConfirmDialogComponent,
    NavbarComponent,
    ProfileCardComponent,
    TagComponent,
    SchoolCardComponent,
    SchoolTypeTagComponent,
    JobCardComponent,
    ProfileProgressCardComponent,
    ProfileHeaderComponent,
    ProgressBarComponent,
    ProgressCardItemComponent,
    EmptyCardComponent,
    ProfileQualificationCardComponent,
    ProfileAboutMeCardComponent,
    ProfilePreferencesCardComponent,
    ProfileWorkExperiencsCardComponent,
    ProfileEducationCardComponent,
    ProfileLanguageCardComponent,
    EditButtonComponent,
    AddButtonComponent,
    SchoolHeaderComponent,
    SchoolHeaderLocationsComponent,
    SchoolNameFormComponent,
    SchoolProgressCardComponent,
    SchoolInfoFormComponent,
    SchoolDocumentsFormComponent,
    SchoolMediaCardComponent,
    VideoPlayerComponent,
    SchoolAboutCardComponent,
    SchoolInfoCardComponent,
    SchoolIntroCardComponent,
    SchoolDocumentsCardComponent,
    SchoolFocusCardComponent,
    SchoolWeofferCardComponent,
    SchoolWeofferFormComponent,
    SchoolContactCardComponent,
    SchoolJobsCardComponent,
    JobHeaderComponent,
    JobItemFieldComponent,
    JobDurationFormComponent,
    JobApplicationDeadlineFormComponent,
    JobWorkloadMatrixFormComponent,
    JobWeOfferCardComponent,
    JobTasksCardComponent,
    JobSkillsCardComponent,
    ApplicationCardComponent,
    ApplicationRowComponent,
    SecondaryButtonComponent,
    SchoolOnboardingComponent,
    FooterComponent,
    PublicToolbarComponent,
    SignupSecurePasswordComponent,
    GoodbyeComponent,
    JobApplyPublicComponent,
    JobProgressCardComponent,
    EmptyBoxComponent,
    RejectMessageDialogComponent,
    WelcomeSchoolCardComponent,
    SecurePasswordHelpComponent,
    PasswordFieldsComponent,
    OrderProcessComponent,
    OrderProcessStartComponent,
    OrderProcessProductsComponent,
    OrderProcessOrderingComponent,
    OrderingAddressComponent,
    OrderingOverviewComponent,
    OrderingConfirmationComponent,
    OrderProcessBenefitComponent,
    SearchAgentComponent,
    HighlightBubbleComponent,
    RichTextFieldComponent,
    RichTextViewComponent,
    EdulogCallbackComponent,
    SchoolsMyProgressCardComponent,
    SchoolMyItemComponent,
    HrJobCardComponent,
    HighlightListSelectionComponent,
    HighlightItemListComponent,
    JobTypeChipComponent,
    PassedTimeInfoComponent,
    JobTopBarComponent,
    JobShareButtonComponent,
    JobMediaCardComponent,
    JobMediaFormComponent,
    JobIntroCardComponent,
    MediaFormCropperComponent,
    JobAdditionalInfosCardComponent,
    JobAdditionalInfosFormComponent,
    JobContactCardComponent,
    JobContactCardItemComponent,
    JobContactFormComponent,
    JobContactFormItemComponent,
    JobContactFormDialogComponent,
    SchoolShareButtonComponent,
    SchoolTopBarComponent,
    TopBarItemComponent,
    TopBarPublishComponent,
    TopBarPreviewComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTooltipModule,
    MatSidenavModule,
    ImageCropperComponent,
    MatRippleModule,
    MatStepperModule,
    MatFormFieldModule,
    VgStreamingModule,
    InfiniteScrollDirective,
    DragScrollComponent,
    DragScrollItemDirective,
    NgxEditorModule.forRoot(ngxEditorConfiguration),
    AuthConfigModule,
    DragDropModule,
    TranslateModule.forRoot(),
    SharedModule,
    MatMenuModule,
  ],
  providers: [
    ...firebaseProviders,
    { provide: ErrorStateMatcher, useClass: InputErrorStateMatcher },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    PermissionsService,
    LocationsService,
    JobService,
    SchoolService,
    ProfileService,
    FileService,
    ApplicationService,
    MatDatepickerModule,
    MatDateFnsModule,
    BreadcrumbService,
    SettingService,
    NotificationService,
    SupportService,
    PublicToolbarService,
    ScreenTrackingService,
    UserTrackingService,
    ApiVideoService,
    NotificationSubscriptionService,
    { provide: MAT_DATE_LOCALE, useValue: de },
    {
      provide: CONFIG,
      useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    for (const icon of CUSTOM_ICONS) {
      iconRegistry.addSvgIcon(
        `custom_${getIconName(icon)}`,
        domSanitizer.bypassSecurityTrustResourceUrl(icon)
      );
    }
  }
}
