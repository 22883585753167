@if (!showEmptyCard) {
  <strong class="font-bold text-2xl md:text-4xl">Sprachen</strong>
  @if (isOwner) {
    <app-edit-button class="pl-4" routerLink="edit/languages"></app-edit-button>
  }
  <div class="flex flex-col md:flex-row pt-4 gap-4 flex-wrap">
    @for (language of profile.languages; track language) {
      <div
        class="flex flex-col bgfill rounded-2xl w-full md:w-[14.3rem] pt-8 pl-6 pr-6 pb-8">
        <strong class="text-base pb-1 font-bold">
          {{ language.language }}
        </strong>
        <span class="text-sm font-very-bright-color pb-4">
          {{ getProficiency(language.proficiency) }}
        </span>
        <div class="flex flex-col grow">
          <div class="flex w-full h-2 items-center relative">
            <div
              [style.width.%]="(language.proficiency + 1) * 20"
              class="h-2 primary-bg-color rounded-md absolute z-10"></div>
            <div class="h-2 grey-bg-color rounded-md absolute w-full"></div>
          </div>
        </div>
      </div>
    }
  </div>
}
@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Sprachen"
    subtitle="Deutsche Sprache, schwere Sprache!"
    description="Sprache ist der Schlüssel zur Welt. Welche sprichst du?"
    link="edit/languages"></app-empty-card>
}
