<main class="contact-form">
  <section class="contact-form__main">
    <header class="contact-form__main__header">
      <h1 class="contact-form__main__header__title">Ansprechperson</h1>
      <p class="contact-form__main__header__text">
        Wähle eine oder mehrere Ansprechpersonen aus, die für dieses Inserat
        zuständig sind oder füge eine neue hinzu. Die hier eingegebenen
        Informationen werden ausschliesslich für dieses Inserat verwendet.
      </p>
    </header>
    <div class="contact-form__main__content">
      @if (!!admins.length) {
        <h2 class="contact-form__main__content__title">
          Vorgeschlagene Kontaktpersonen:
        </h2>
        @for (admin of admins; track admin.userId) {
          <app-job-contact-form-item
            [contact]="admin"
            (changed)="change($event)" />
        }
        @if (!!contacts.length) {
          <hr class="contact-form__main__content__divider" />
        }
      }
      @if (!!contacts.length) {
        <h2 class="contact-form__main__content__title">
          Weitere Ansprechpersonen (ausserhalb Bildigsnetz-Community)
        </h2>
        @for (contact of contacts; track contact.userId) {
          <app-job-contact-form-item
            [contact]="contact"
            (changed)="change($event)"
            (delete)="remove($event)" />
        }
      }
    </div>
    <footer class="contact-form__main__footer">
      <button
        mat-stroked-button
        color="primary"
        class="mdc-button--light mdc-button--grey"
        [disabled]="loading"
        (click)="add()">
        <mat-icon class="material-symbols-rounded font-bold">add</mat-icon>
        Hinzufügen
      </button>
      <aside class="contact-form__main__footer__aside">
        <a mat-button [routerLink]="['/jobs', id]" [disabled]="loading">
          Abbrechen
        </a>
        <app-action-button
          [loading]="loading"
          [disabled]="loading || !isValid()"
          (onClick)="save()"
          text="Speichern" />
      </aside>
    </footer>
  </section>
</main>
