<section class="contact-form-dialog">
  <header mat-dialog-title class="contact-form-dialog__header">
    <h3 class="contact-form-dialog__header__title">Foto bearbeiten</h3>
    <button
      mat-icon-button
      mat-dialog-close
      class="contact-form-dialog__header__close">
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </header>
  <main mat-dialog-content class="contact-form-dialog__content">
    <figure class="flex items-center justify-center w-full aspect-square">
      <image-cropper
        alignImage="center"
        format="jpeg"
        backgroundColor="#fff"
        class="image-cropper"
        output="blob"
        [class.hidden]="loading"
        [imageBase64]="base64Image"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [imageQuality]="100"
        [onlyScaleDown]="true"
        [resizeToWidth]="dimension.width"
        [resizeToHeight]="dimension.height"
        [containWithinAspectRatio]="true"
        [roundCropper]="true"
        [allowMoveImage]="true"
        [(transform)]="transform"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="loading = false" />
      @if (loading) {
        <mat-spinner [diameter]="30" />
      }
    </figure>
  </main>
  <footer mat-dialog-actions class="contact-form-dialog__actions">
    <div class="contact-form-dialog__actions__zoom">
      <button mat-icon-button color="grey" (click)="zoomOut()">
        <mat-icon class="material-symbols-rounded"> zoom_out </mat-icon>
      </button>
      <button mat-icon-button class="ml-0" color="grey" (click)="zoomIn()">
        <mat-icon class="material-symbols-rounded"> zoom_in </mat-icon>
      </button>
    </div>
    <div class="contact-form-dialog__actions__save">
      <button mat-button mat-dialog-close>Abbrechen</button>
      <app-action-button
        [disabled]="loading"
        [mat-dialog-close]="croppedImage()"
        text="Speichern" />
    </div>
  </footer>
</section>
