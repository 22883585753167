@if (!showEmptyCard) {
  <div class="flex justify-between md:justify-normal">
    <div
      class="flex gap-2 md:gap-6 flex-row flex-wrap text-sm md:text-base pb-2 md:pb-0 w-full">
      @if (school.isRoot) {
        <div
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none basis-auto xl:w-fit flex gap-3 items-center py-1 px-4 rounded-full">
          <mat-icon
            class="material-symbols-rounded mb-1 self-center scale-[130%]"
            [svgIcon]="getIcon(school.type!, 'address')"></mat-icon>
          @if (numberOfLocations === 1) {
            <div class="pt-1 md:pt-0.5">
              <strong class="font-bold">1</strong>
              Standort
            </div>
          }
          @if (numberOfLocations > 1) {
            <div class="pt-1 md:pt-0.5">
              <strong class="font-bold">
                {{ numberOfLocations }}
              </strong>
              Standorte
            </div>
          }
        </div>
      }
      @if (!!school.countEmployees) {
        <div
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none xl:basis-auto xl:w-fit flex gap-6 items-center py-1 px-4 rounded-full">
          <mat-icon
            class="self-center scale-[210%] mb-1 pl-1"
            [svgIcon]="getIcon(school.type!, 'teachers')"></mat-icon>
          <div class="pt-1 md:pt-0.5">
            <strong class="font-bold">{{ school.countEmployees }}</strong>
            Mitarbeitende
          </div>
        </div>
      }
      @if (!!school.countStudents) {
        <div
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none xl:basis-auto xl:w-fit flex gap-4 items-center py-1 pr-4 pl-6 rounded-full">
          <mat-icon
            class="scale-[170%]"
            svgIcon="custom_school_students"></mat-icon>
          <div class="pt-1 md:pt-0.5">
            <strong class="font-bold">{{ school.countStudents }}</strong>
            Lernende
          </div>
        </div>
      }
      @if (!!school.address?.street) {
        <a
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none xl:basis-auto xl:w-fit flex gap-1 items-center py-1 pl-2 pr-4 rounded-full text-bn-primary-blue"
          [href]="getGmapsLink()"
          target="_blank">
          <mat-icon class="material-symbols-rounded self-center scale-110"
            >location_on</mat-icon
          >
          <div class="pt-1 md:pt-0.5">
            {{ getLocationText() }}
          </div>
        </a>
      }
      @if (school.isRoot && !!school.address?.website) {
        <a
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none xl:basis-auto xl:w-fit flex gap-2 items-center py-1 pl-3 pr-4 rounded-full text-bn-primary-blue"
          [href]="getSchoolWebsite()"
          target="_blank">
          <mat-icon
            class="self-center"
            [svgIcon]="getIcon(school.type!, 'website')"></mat-icon>
          <div class="pt-1 md:pt-0.5">
            <div>Website</div>
          </div>
        </a>
      }
      @if (!!rootSchool?.cooperatingCollegeOfEducation) {
        <a
          [ngClass]="{
            'bg-bn-public-school-color': getType(school.type!) === 'public',
            'bg-bn-private-school-color': getType(school.type!) === 'private',
            'bg-bn-special-school-color': getType(school.type!) === 'special'
          }"
          class="h-9 flex-none xl:basis-auto xl:w-fit flex gap-3 items-center py-1 px-4 rounded-full text-bn-primary-blue"
          [href]="
            getCollegeOfEducationWebsite(
              rootSchool?.cooperatingCollegeOfEducation!
            )
          "
          target="_blank">
          <mat-icon
            class="self-center scale-125"
            [svgIcon]="getIcon(school.type!, 'coop-ph')"></mat-icon>
          <div class="pt-1 md:pt-0.5">
            Kooperationsschule {{ rootSchool?.cooperatingCollegeOfEducation }}
          </div>
        </a>
      }
    </div>
    <div class="self-end">
      @if (isEditable) {
        <app-edit-button color="dark" routerLink="edit/info" />
      }
    </div>
  </div>
}

@if (showEmptyCard && isEditable) {
  <a class="grey" mat-stroked-button color="primary" routerLink="edit/info">
    <mat-icon class="material-symbols-rounded scale-125">add</mat-icon>
    <span> Steckbrief hinzufügen </span>
  </a>
}
