@if (job$ | async; as job) {
  <div class="flex justify-center">
    <div class="w-full max-w-[1120px] grid grid-cols-12">
      <div class="col-span-12 lg:col-span-10 lg:col-start-2">
        <div class="flex flex-col gap-5 md:gap-10">
          <app-job-top-bar
            [job]="job"
            [isOwner]="isOwner"
            [isPreview]="isPreview"
            (jobStatusChange)="reload()" />

          <app-job-header [job]="job" [isEditable]="isEditable(job)" />

          <div class="border-t-2 border-bn-grey-border h-5 md:h-10"></div>
        </div>
        <div class="flex flex-col gap-10 md:gap-20">
          @if (!job.isPublic && isEditable(job)) {
            <app-job-progress-card [job]="job" />
          }

          @if (shouldRenderChild(!!job.video, job)) {
            <app-job-intro-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!jobService.isWorkdayMatrixEmpty(job), job)) {
            <app-job-workload-matrix-form
              id="workload-matrix"
              [job]="job"
              [isEditable]="isEditable(job)"
              (changeWorkdayMatrix)="reload()" />
          }

          @if (shouldRenderChild(!!job.media?.length, job)) {
            <app-job-media-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.weOffer, job)) {
            <app-job-we-offer-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.yourTasks, job)) {
            <app-job-tasks-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.yourSkills, job)) {
            <app-job-skills-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.additionalInfos, job)) {
            <app-job-add-infos-card
              [job]="job"
              [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderContacts(job)) {
            <app-job-contact-card [job]="job" [isEditable]="isEditable(job)" />
          }
        </div>
      </div>
    </div>
  </div>
}
