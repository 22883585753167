<div class="flex justify-center">
  <div class="w-full max-w-[1120px]">
    @if (school$ | async; as school) {
      <div class="flex flex-col gap-5 md:gap-10">
        <app-school-top-bar
          [school]="school"
          [isOwner]="isOwner"
          [isPreview]="isPreview"
          (schoolStatusChange)="reload()" />

        <app-school-header [school]="school" [isEditable]="isEditable" />

        @if (!school.isPublic && isEditable) {
          <app-school-progress-card [school]="school" />
        }
      </div>
      <div class="flex flex-col gap-10 md:gap-20 pt-10 md:pt-20">
        <app-school-intro-card
          id="intro-card"
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-about-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-media-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-weoffer-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-focus-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-documents-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <app-school-contact-card
          class="conditional-hide"
          [school]="school"
          [isEditable]="isEditable" />

        <div
          class="border-2 border-bn-grey-border only-school-job-card-exists w-full"></div>

        <app-school-jobs-card class="conditional-hide" [school]="school" />
      </div>
    }
  </div>
</div>
