import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { SchoolService } from 'src/app/services/school.service';
import { School } from 'src/app/types/school';

@Component({
  selector: 'app-school-top-bar',
  templateUrl: './school-top-bar.component.html',
  styleUrl: './school-top-bar.component.scss',
})
export class SchoolTopBarComponent implements OnChanges {
  @Input({ required: true }) school!: School;
  @Input({ required: true }) isOwner!: boolean;
  @Input({ required: true }) isPreview!: boolean;
  @Output() schoolStatusChange = new EventEmitter();

  actionInProgress: 'closePreview' | 'publish' | 'preview' | null = null;
  canPublish = false;
  // Is necessary to bind the context to the functions
  ctxActions = {
    closePreview: async () => this.executeClosePreviewing(),
    preview: async () => this.executePreviewing(),
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['school']) {
      const { hasType, hasInfo, hasAbout, hasContacts } =
        this.schoolService.checkRequiredFields(changes['school'].currentValue);
      this.canPublish =
        hasType &&
        hasInfo &&
        hasAbout &&
        hasContacts &&
        this.actionInProgress === null;
    }
  }

  getReturnUrl() {
    return this.isOwner ? '/my-schools' : '/schools';
  }

  async executePublishing() {
    this.actionInProgress = 'publish';
    this.schoolService.publish(this.school).subscribe(() => {
      this.schoolStatusChange.emit();
      this.actionInProgress = null;

      this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: {
          onlyAcceptButton: true,
          buttonColor: 'primary',
          text: 'Gratuliere - du hast dein Schulprofil erfolgreich veröffentlicht. Somit hast du dich wirksam in der Gesellschaft und spezifisch im Bildungsmarkt positioniert. Deine Schule kann nun unter “Schulen” gefunden werden.',
        },
      });
    });
  }

  async executePreviewing() {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { preview: true },
      queryParamsHandling: 'merge',
    });
  }

  async executeClosePreviewing() {
    const currentParams = { ...this.route.snapshot.queryParams };
    delete currentParams['preview'];

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: currentParams,
    });
  }
}
