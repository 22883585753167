import { Component, inject, Input } from '@angular/core';
import { Job } from '../../../../types/job';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { JobService } from '../../../../services/job.service';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-job-share-button',
  standalone: false,
  templateUrl: './job-share-button.component.html',
  styleUrl: './job-share-button.component.scss',
})
export class JobShareButtonComponent {
  @Input({ required: true }) job!: Job;

  readonly router = inject(Router);
  readonly jobService = inject(JobService);
  readonly analytics = inject(Analytics);
  canShare = navigator.share !== undefined;

  getJobLevels(job: Job) {
    return this.jobService.getLevelsTitle(
      job.schoolLevels || [],
      job.schoolLevelDescriptions || []
    );
  }

  getTitle() {
    const jobType = this.job.isFullTimeJob
      ? 'Festanstellung'
      : 'Stellvertretung';
    return `Schau dir diese spannende ${jobType} auf Bildigsnetz an!
Ich habe gerade eine ${jobType} gesehen, welche dich interessieren könnte.\n`;
  }

  getText() {
    if (this.job.isFullTimeJob) {
      const date = new Date(this.job.durationFrom ?? new Date());
      const startDate =
        new Date().toDateString() > date.toISOString()
          ? format(date, 'dd.MM.yyyy')
          : 'Ab sofort';

      return `\n${this.job.schoolName}, ${this.job.schoolAddress?.city}
Pensum: ${this.job.activityRangeInPercentage?.from}% bis ${this.job.activityRangeInPercentage?.to}% | Lektionen: ${this.job.activityRangeInHours?.from} bis ${this.job.activityRangeInHours?.from}
${this.job.function}, ${this.getJobLevels(this.job)},
Start: ${startDate}`;
    }

    return `\n${this.job.schoolName}, ${this.job.schoolAddress?.city}
${this.jobService.getScheduleText(this.job)}
${this.job.function}, ${this.getJobLevels(this.job)}
${this.jobService.getSubstitutionPeriodText(this.job)}`;
  }

  getURL() {
    return this.router
      .createUrlTree(['/jobs', this.job.id], {
        queryParams: { share: true },
      })
      .toString();
  }
  async onShare() {
    const url = this.getURL();
    const text = `${this.getTitle()}\r${this.getText()}`;

    logEvent(this.analytics, 'share_link', { url });

    if (this.canShare) {
      await navigator.share({ text, url });
    }
  }
}
