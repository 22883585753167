@if (!showEmptyCard) {
  <div class="flex flex-col">
    <div class="flex justify-between">
      <strong class="font-bold text-2xl md:text-4xl">Über uns</strong>
      @if (isEditable) {
        <app-edit-button routerLink="edit/about"></app-edit-button>
      }
    </div>
    <app-highlight-item-list
      class="pt-4"
      [highlights]="school.aboutHighlights" />
    <app-rich-text-view
      class="pt-4"
      [html]="school.about_html"
      tailwindCssLineClamp="line-clamp-[17]"></app-rich-text-view>
  </div>
}
@if (showEmptyCard && isEditable) {
  <app-empty-card
    title="Über uns"
    [subtitle]="subtitle"
    [description]="description"
    link="edit/about"></app-empty-card>
}
