@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div class="flex w-fit">
      <strong class="font-bold text-2xl md:text-4xl">
        Zusätzliche Infos
      </strong>
      @if (isEditable) {
        <app-edit-button class="pl-4" routerLink="edit/addInfos" />
      }
    </div>
    <app-rich-text-view
      [html]="job.additionalInfos_html"
      tailwindCssLineClamp="line-clamp-[10]" />
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Zusätzliche Infos"
    subtitle="Gibt es sonst noch etwas, was relevant ist?"
    description="Ergänze hier bei Bedarf Infos zum Bewerbungsprozess, speziellen Unterrichtszeiten oder sonst etwas."
    link="edit/addInfos" />
}
