@if (!showEmptyCard() && !loading) {
  <section class="flex flex-col gap-y-5" id="video--upload">
    @if (isEditable()) {
      <header class="flex justify-between">
        <strong class="font-bold text-2xl md:text-4xl">Video</strong>
        <app-edit-button (click)="remove()" icon="delete" />
      </header>
    }
    <app-video-player [hls]="job().video?.hls" type="video/mp4" />
  </section>
}

@if (showEmptyCard() || loading) {
  <section id="video--upload">
    <input
      type="file"
      hidden
      [accept]="mediaVideoService.acceptTypes"
      (change)="upload($event)"
      #video />
    <app-empty-card
      title="Video"
      subtitle="Ein passendes Stellenvideo"
      description="Du hast die Möglichkeit, hier das Stelleninserat mit einem Video zu bereichern."
      icon="upload"
      [buttonText]="buttonText"
      [loading]="loading"
      (triggerAction)="video.click()" />
  </section>
}
