import {
  Component,
  ElementRef,
  inject,
  input,
  output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Contact, ContactFile } from '../../job-contact-form.component';
import { getPublicFileUrl } from '../../../../../../core/helpers';
import { JobContactFormDialogComponent } from '../job-contact-form-dialog/job-contact-form-dialog.component';

@Component({
  selector: 'app-job-contact-form-item',
  templateUrl: './job-contact-form-item.component.html',
  styleUrl: './job-contact-form-item.component.scss',
})
export class JobContactFormItemComponent {
  readonly contact = input.required<Contact>();
  readonly delete = output<string>();
  readonly changed = output<{ userId: Contact['userId'] } & Partial<Contact>>();

  readonly acceptTypes = ['.jpg', '.jpeg', '.png', '.tiff'];
  protected readonly getPublicFileUrl = getPublicFileUrl;

  base64Avatar: ContactFile['base64'] = undefined;

  readonly dialog = inject(MatDialog);

  @ViewChild('file')
  avatar!: ElementRef;

  onDelete(): void {
    this.delete.emit(this.contact().userId);
  }

  onChanged(contact: Partial<Contact>): void {
    this.changed.emit({
      userId: this.contact().userId,
      ...contact,
    });
  }

  openDialog(event: Event): void {
    const file = (event.target as HTMLInputElement)?.files?.[0];

    if (file) {
      const dialogRef = this.dialog.open(JobContactFormDialogComponent, {
        data: { file },
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((file: ContactFile) => {
        this.avatar.nativeElement.value = '';
        if (file) {
          this.base64Avatar = file.base64;
          this.changed.emit({
            userId: this.contact().userId,
            file,
          });
        }
      });
    }
  }
}
