@if (jobs && jobs.length > 0) {
  <div class="flex flex-col">
    <div>
      <strong class="font-bold text-2xl md:text-4xl">Offene Stellen</strong>
    </div>
    <div class="grid grid-cols-12 gap-4 pt-8">
      @for (job of jobs; track job.id) {
        <app-job-card
          [routerLink]="['/jobs', job.id]"
          class="cursor-pointer col-span-12 md:col-span-6 xl:col-span-4"
          [job]="job"></app-job-card>
      }
    </div>
  </div>
}
