import { Component, inject, model, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
} from 'ngx-image-compress';

import { ContactFile } from '../../job-contact-form.component';
import { FileService } from '../../../../../../services/files.service';

@Component({
  selector: 'app-job-contact-form-dialog',
  templateUrl: './job-contact-form-dialog.component.html',
  styleUrl: './job-contact-form-dialog.component.scss',
})
export class JobContactFormDialogComponent implements OnInit {
  loading: boolean = false;
  transform: ImageTransform = { scale: 1, translateUnit: 'px' };
  base64Image?: DataUrl = undefined;
  readonly factor: number = 4;
  readonly dimension: { width: number; height: number } = {
    width: 220,
    height: 220,
  };

  readonly fileService = inject(FileService);
  readonly dialogRef = inject(DialogRef<JobContactFormDialogComponent>);
  readonly data = inject<{ file: File }>(MAT_DIALOG_DATA);
  readonly imageCompress = inject(NgxImageCompressService);
  readonly croppedImage = model<ContactFile>();

  ngOnInit(): void {
    this.selectFile(this.data.file);
  }

  private selectFile(file: File): void {
    this.loading = true;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = e => this.compressFile(e.target?.result as DataUrl);
      reader.readAsDataURL(file);
    }
  }

  private compressFile(url: DataUrl): void {
    this.imageCompress
      .compressFile(
        url,
        DOC_ORIENTATION.Default,
        50,
        100,
        Math.ceil(this.dimension.width * this.factor),
        Math.ceil(this.dimension.height * this.factor)
      )
      .then(result => (this.base64Image = result));
  }

  zoomOut() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! - 0.1,
    };
  }

  zoomIn() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! + 0.1,
    };
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    this.croppedImage.set({
      base64: event.blob
        ? await this.fileService.blobToBase64(event.blob)
        : undefined,
      blob: event.blob ?? undefined,
    });
  }
}
