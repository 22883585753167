import { Component, inject, Input } from '@angular/core';
import { School } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import { getCityWithCantonAbbrevation } from '../../../../core/helpers';

@Component({
  selector: 'app-school-share-button',
  templateUrl: './school-share-button.component.html',
  styleUrl: './school-share-button.component.scss',
})
export class SchoolShareButtonComponent {
  @Input({ required: true }) school!: School;

  readonly router = inject(Router);
  readonly schoolService = inject(SchoolService);
  readonly analytics = inject(Analytics);
  canShare = navigator.share !== undefined;

  getSchoolTitle(school: School) {
    return this.schoolService.getLevelsTitle(
      school.levels || [],
      school.levelDescriptions || []
    );
  }

  getSchoolLocation(school: School) {
    return getCityWithCantonAbbrevation(school.address);
  }

  getTitle() {
    return `Schau dir dieses spannende Schulprofil auf Bildigsnetz an:`;
  }

  getText() {
    return `${this.school.name}\n${this.getSchoolTitle(this.school)}\n${this.getSchoolLocation(this.school)}`;
  }

  getURL() {
    return this.router
      .createUrlTree(['/schools', this.school.id], {
        queryParams: { share: true },
      })
      .toString();
  }

  async onShare() {
    const url = this.getURL();
    const text = `${this.getTitle()}\n${this.getText()}`;

    logEvent(this.analytics, 'share_link', { url });

    if (this.canShare) {
      await navigator.share({ text, url });
    }
  }
}
