@if (cropImage) {
  <app-media-form-cropper [data]="cropImage" (cropped)="cropped($event)" />
} @else {
  <div class="grid grid-cols-12">
    <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
      <div class="flex flex-col">
        <h1 class="font-bold text-4xl">Impressionen</h1>
        @if (!edit) {
          <p class="text-base text-bn-bright mb-0">
            Lade hier <strong>Bildimpressionen</strong> und euren
            <strong>Schulsong</strong> hoch und wähle per Drag and Drop die
            Reihenfolge!
          </p>
        }
      </div>

      @if (!edit) {
        <div class="hint">
          <mat-icon class="material-symbols-rounded pr-8"> info </mat-icon>
          <div class="text-base">
            Kontaktiere Bildigsnetz für Unterstützung bei der professionellen
            Produktion von Fotos und Videos.
          </div>
        </div>
      }

      <div class="flex flex-col xl:flex-row justify-end gap-y-8">
        <div class="flex items-center justify-between gap-x-8 xl:order-2">
          @if (!edit) {
            <a mat-button [routerLink]="['/schools', id]" [disabled]="loading">
              Abbrechen
            </a>
            <app-action-button
              [loading]="loading"
              [disabled]="!mediaFilesValid() || edit"
              (onClick)="save()"
              text="Speichern" />
          } @else {
            <button mat-button (click)="toggleMode(true)" [disabled]="loading">
              Abbrechen
            </button>
          }
        </div>
        <div class="flex-1">
          <button
            mat-stroked-button
            color="primary"
            class="mdc-button--light mdc-button--grey w-full xl:w-auto xl:order-1"
            (click)="toggleMode()"
            [disabled]="mediaFiles.length <= 1">
            {{ edit ? 'Reihenfolge übernehmen' : 'Reihenfolge anpassen' }}
          </button>
        </div>
      </div>

      <section class="flex flex-col gap-y-5">
        @if (!edit) {
          <header class="card">
            <input
              hidden
              type="file"
              [accept]="acceptTypes"
              (change)="selectFiles($event)"
              #file />
            <mat-form-field
              class="w-full cursor-pointer"
              (click)="file.click()">
              <input
                class="cursor-pointer"
                placeholder="Foto oder Audiodatei"
                matInput
                readonly
                [formControl]="upload" />
              <button matSuffix mat-icon-button>
                <mat-icon class="material-symbols-rounded">upload</mat-icon>
              </button>
              <mat-error *ngIf="upload.hasError('maxCount')">
                Es sind max. {{ max }} Anhänge erlaubt
              </mat-error>
            </mat-form-field>
          </header>
        }

        @if (mediaFiles.length) {
          <ul
            class="flex flex-col gap-y-5"
            cdkDropList
            (cdkDropListDropped)="sortMediaFiles($event)">
            @for (
              mediaFile of mediaFiles;
              track mediaFile.media?.filename;
              let i = $index;
              let last = $last
            ) {
              <li
                cdkDrag
                class="card"
                [attr.id]="last ? 'card--last' : undefined"
                [ngClass]="{ 'card--edit': edit }"
                [cdkDragData]="i"
                [cdkDragDisabled]="loading || !edit">
                <article class="media">
                  <header class="media__header">
                    <div class="media__header__cover">
                      @if (
                        mediaFile.media?.type === 'audio' &&
                        !mediaFile.media?.coverImage?.displayUrl
                      ) {
                        <input
                          hidden
                          type="file"
                          [accept]="imageExtensions"
                          (change)="selectCover($event, i)"
                          #cover />
                        <button
                          class="media__header__cover__upload"
                          [disabled]="loading || edit"
                          (click)="cover.click()">
                          <mat-icon class="material-symbols-rounded"
                            >upload</mat-icon
                          >
                          <span class="text-base pt-4">Titelbild wählen*</span>
                        </button>
                      } @else {
                        <img
                          alt="Cover"
                          class="object-cover h-full w-full"
                          [src]="
                            mediaFile.media?.coverImage?.displayUrl ??
                            mediaFile.media?.displayUrl
                          " />
                      }
                    </div>
                    <aside class="media__header__meta">
                      <div class="media__header__meta__actions">
                        @if (edit) {
                          <button
                            cdkDragHandle
                            mat-stroked-button
                            class="mdc-button--grey media__header__meta__actions__grab">
                            <mat-icon
                              class="material-symbols-rounded scale-125 m-0">
                              drag_indicator
                            </mat-icon>
                          </button>
                        } @else {
                          <button
                            mat-stroked-button
                            color="primary"
                            class="mdc-button--light mdc-button--grey"
                            (click)="deleteMedia(i)">
                            <mat-icon
                              class="material-symbols-rounded scale-125">
                              delete
                            </mat-icon>
                            Löschen
                          </button>
                        }
                      </div>
                      @if (mediaFile.media?.type === 'audio') {
                        <audio class="media__header__meta__audio" controls>
                          <source
                            [src]="mediaFile.media?.displayUrl"
                            type="audio/mpeg" />
                          Ihr Browser unterstützt das Audioelement nicht.
                        </audio>
                      }
                    </aside>
                  </header>
                  <main class="media_main" [class.hidden]="edit">
                    <mat-form-field class="w-full">
                      <mat-label>Titel</mat-label>
                      <input
                        matInput
                        type="text"
                        [value]="mediaFile.title"
                        [formControl]="mediaFile.title" />
                    </mat-form-field>
                    <mat-form-field class="w-full">
                      <mat-label>Beschreibung</mat-label>
                      <input
                        matInput
                        type="text"
                        [value]="mediaFile.description"
                        [formControl]="mediaFile.description" />
                    </mat-form-field>
                  </main>
                </article>
              </li>
            }
          </ul>
        }
      </section>

      @if (!edit) {
        <div class="flex">
          <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
            info
          </mat-icon>
          <div class="text-base text-bn-bright">
            Achte auf eine gute Qualität der Fotos und Audiodateien. Unterstützt
            werden JPG, JPEG, PNG für Fotos und MP3 für Audiodateien.
          </div>
        </div>
      }
    </div>
  </div>
}
