@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div class="flex w-fit">
      <strong class="font-bold text-2xl md:text-4xl">Anforderungsprofil</strong>
      @if (isEditable) {
        <app-edit-button class="pl-4" routerLink="edit/skills" />
      }
    </div>
    <app-rich-text-view
      [html]="job.yourSkills_html"
      tailwindCssLineClamp="line-clamp-[10]" />
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Anforderungsprofil"
    subtitle="Was erwartest du von deiner neuen Fachkraft?"
    description='Fachkenntnisse, Berufserfahrung und Qualifikationen. Hier schreibst du bspw., welche Anforderungen ein "must have" und welche "nice to have" sind.'
    link="edit/skills" />
}
