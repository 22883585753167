<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Mindestqualifikation</h1>

    <div class="flex flex-col">
      <mat-checkbox color="primary" formControlName="diplom">
        Diplom
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="student">
        in Ausbildung
      </mat-checkbox>
      @if (!!form.controls.student.value) {
        <mat-checkbox color="primary" formControlName="baseYear">
          Basisjahr abgeschlossen
        </mat-checkbox>
      }
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/jobs', id]">Abbrechen</a>
      <app-action-button
        [loading]="loading"
        (onClick)="save()"
        [disabled]="!form.valid"
        text="Speichern"></app-action-button>
    </div>
  </div>
</form>
