import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivate } from '@angular/fire/auth-guard';
import { LoginComponent } from './pages/login/login.component';
import { SchoolsComponent } from './pages/schools/schools.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SchoolVerificationComponent } from './pages/register-school/school-verification/school-verification.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileDetailComponent } from './pages/profiles/profile-detail/profile-detail.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { ProfileWizardComponent } from './pages/profiles/profile-wizard/profile-wizard.component';
import { SchoolProfileComponent } from './pages/schools/school-profile/school-profile.component';
import { SchoolAboutFormComponent } from './pages/schools/forms/school-about-form/school-about-form.component';
import { SchoolFocusFormComponent } from './pages/schools/forms/school-focus-form/school-focus-form.component';
import { SchoolMediaFormComponent } from './pages/schools/forms/school-media-form/school-media-form.component';
import { SchoolPermissionsFormComponent } from './pages/schools/forms/school-permissions-form/school-permissions-form.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { JobDetailComponent } from './pages/jobs/job-detail/job-detail.component';
import { JobCreateComponent } from './pages/jobs/job-create/job-create.component';
import { JobDateFormComponent } from './pages/jobs/forms/job-date-form/job-date-form.component';
import { JobRequirementsFormComponent } from './pages/jobs/forms/job-requirements-form/job-requirements-form.component';
import { JobSchoolLevelsFormComponent } from './pages/jobs/forms/job-school-levels-form/job-school-levels-form.component';
import { JobSkillsFormComponent } from './pages/jobs/forms/job-skills-form/job-skills-form.component';
import { JobSubjectsFormComponent } from './pages/jobs/forms/job-subjects-form/job-subjects-form.component';
import { JobTasksFormComponent } from './pages/jobs/forms/job-tasks-form/job-tasks-form.component';
import { JobWeOfferFormComponent } from './pages/jobs/forms/job-we-offer-form/job-we-offer-form.component';
import { JobWorkloadFormComponent } from './pages/jobs/forms/job-workload-form/job-workload-form.component';
import { JobFunctionFormComponent } from './pages/jobs/forms/job-function-form/job-function-form.component';
import { ProfileAvatarFormComponent } from './pages/profiles/forms/profile-avatar-form/profile-avatar-form.component';
import { ProfileBackgroundFormComponent } from './pages/profiles/forms/profile-background-form/profile-background-form.component';
import { ProfileJobFormComponent } from './pages/profiles/forms/profile-job-form/profile-job-form.component';
import { ProfileSloganFormComponent } from './pages/profiles/forms/profile-slogan-form/profile-slogan-form.component';
import { ProfileAboutFormComponent } from './pages/profiles/forms/profile-about-form/profile-about-form.component';
import { ProfileSubjectsFormComponent } from './pages/profiles/forms/profile-subjects-form/profile-subjects-form.component';
import { ProfileCertificationsFormComponent } from './pages/profiles/forms/profile-certifications-form/profile-certifications-form.component';
import { ProfileWorkExperienceFormComponent } from './pages/profiles/forms/profile-work-experience-form/profile-work-experience-form.component';
import { ProfileEducationFormComponent } from './pages/profiles/forms/profile-education-form/profile-education-form.component';
import { ProfilePreferencesFormComponent } from './pages/profiles/forms/profile-preferences-form/profile-preferences-form.component';
import {
  authGuard,
  redirectLoggedInToTarget,
  redirectLoggedInToHome,
  redirectUnauthorizedToLogin,
  isCurrentUserAuthGuard,
} from './core/guards';
import { ProfileLanguagesFormComponent } from './pages/profiles/forms/profile-languages-form/profile-languages-form.component';
import { SchoolAvatarFormComponent } from './pages/schools/forms/school-avatar-form/school-avatar-form.component';
import { SchoolBackgroundFormComponent } from './pages/schools/forms/school-background-form/school-background-form.component';
import { MyApplicationsComponent } from './pages/applications/my-applications/my-applications.component';
import { ApplicationDetailComponent } from './pages/applications/application-detail/application-detail.component';
import { JobApplyComponent } from './pages/jobs/job-apply/job-apply.component';
import { SchoolsMyComponent } from './pages/schools/schools-my/schools-my.component';
import { SchoolsJobsComponent } from './pages/schools/schools-jobs/schools-jobs.component';
import { SchoolsJobsApplicantsComponent } from './pages/schools/schools-jobs-applicants/schools-jobs-applicants.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsAccountComponent } from './pages/settings/settings-account/settings-account.component';
import { SettingsChangeEmailComponent } from './pages/settings/settings-change-email/settings-change-email.component';
import { SettingsChangeNameComponent } from './pages/settings/settings-change-name/settings-change-name.component';
import { SettingsChangePasswordComponent } from './pages/settings/settings-change-password/settings-change-password.component';
import { SettingsChangePasswordSecureHelpComponent } from './pages/settings/settings-change-password-secure-help/settings-change-password-secure-help.component';
import { SettingsContactComponent } from './pages/settings/settings-contact/settings-contact.component';
import { SettingsDeleteAccountComponent } from './pages/settings/settings-delete-account/settings-delete-account.component';
import { SettingsMarketingComponent } from './pages/settings/settings-marketing/settings-marketing.component';
import { SettingsPrivacyComponent } from './pages/settings/settings-privacy/settings-privacy.component';
import { PasswordForgetComponent } from './pages/login/password-forget/password-forget.component';
import { SettingsChangeNameInfoComponent } from './pages/settings/settings-change-name-info/settings-change-name-info.component';
import { SchoolNameFormComponent } from './pages/schools/forms/school-name-form/school-name-form.component';
import { SchoolInfoFormComponent } from './pages/schools/forms/school-info-form/school-info-form.component';
import { SchoolDocumentsFormComponent } from './pages/schools/forms/school-documents-form/school-documents-form.component';
import { SchoolWeofferFormComponent } from './pages/schools/forms/school-weoffer-form/school-weoffer-form.component';
import { JobDurationFormComponent } from './pages/jobs/forms/job-duration-form/job-duration-form.component';
import { JobApplicationDeadlineFormComponent } from './pages/jobs/forms/job-application-deadline-form/job-application-deadline-form.component';
import { SchoolOnboardingComponent } from './pages/schools/school-onboarding/school-onboarding.component';
import { SignupSecurePasswordComponent } from './pages/signup/signup-secure-password/signup-secure-password.component';
import { GoodbyeComponent } from './pages/goodbye/goodbye.component';
import { JobApplyPublicComponent } from './pages/jobs/job-apply-public/job-apply-public.component';
import { SearchAgentComponent } from './pages/search-agent/search-agent.component';
import { EdulogCallbackComponent } from './pages/auth/edulog-callback.component';
import { JobMediaFormComponent } from './pages/jobs/forms/job-media-form/job-media-form.component';
import { JobAdditionalInfosFormComponent } from './pages/jobs/forms/job-add-infos-form/job-add-infos-form.component';
import { JobContactFormComponent } from './pages/jobs/forms/job-contact-form/job-contact-form.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    ...canActivate(redirectLoggedInToTarget),
  },
  {
    path: 'password-forget',
    component: PasswordForgetComponent,
    ...canActivate(redirectLoggedInToTarget),
  },
  {
    path: 'signup',
    component: SignupComponent,
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'signup/secure-password',
    component: SignupSecurePasswordComponent,
  },
  //#region profiles
  {
    path: 'profile',
    component: ProfilesComponent,
    canActivate: [authGuard],
  },
  {
    path: 'profile/:id',
    component: ProfileDetailComponent,
    canActivate: [authGuard],
  },
  {
    path: 'me/:id',
    component: ProfileDetailComponent,
    canActivate: [authGuard],
  },
  {
    path: 'me/:id/edit/avatar',
    component: ProfileAvatarFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/background',
    component: ProfileBackgroundFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/job',
    component: ProfileJobFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/slogan',
    component: ProfileSloganFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/about',
    component: ProfileAboutFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/subjects',
    component: ProfileSubjectsFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/certifications',
    component: ProfileCertificationsFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/experience/:experienceId',
    component: ProfileWorkExperienceFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/education/:educationId',
    component: ProfileEducationFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/preferences',
    component: ProfilePreferencesFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'me/:id/edit/languages',
    component: ProfileLanguagesFormComponent,
    canActivate: [isCurrentUserAuthGuard],
  },
  {
    path: 'welcome',
    component: ProfileWizardComponent,
    canActivate: [redirectUnauthorizedToLogin],
  },
  //#endregion
  //#region school registration/verification process
  {
    path: 'school-registration/verify/:code',
    component: SchoolVerificationComponent,
    canActivate: [authGuard],
  },
  //#endregion
  //#region schools
  {
    path: 'schools/:id/onboarding',
    component: SchoolOnboardingComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/name',
    component: SchoolNameFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/avatar',
    component: SchoolAvatarFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/background',
    component: SchoolBackgroundFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/about',
    component: SchoolAboutFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/info',
    component: SchoolInfoFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/pedagogic-focus',
    component: SchoolFocusFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/media',
    component: SchoolMediaFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/documents',
    component: SchoolDocumentsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/weoffer',
    component: SchoolWeofferFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools/:id/edit/permissions',
    component: SchoolPermissionsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'schools',
    component: SchoolsComponent,
  },
  {
    path: 'schools/:id',
    component: SchoolProfileComponent,
    data: { breadcrumbs: true },
  },
  {
    path: 'my-schools',
    component: SchoolsMyComponent,
    canActivate: [authGuard],
  },
  {
    path: 'my-schools/:id/jobs',
    component: SchoolsJobsComponent,
    canActivate: [authGuard],
    data: { breadcrumbs: true, archived: false },
  },
  {
    path: 'my-schools/:id/jobs/archived',
    component: SchoolsJobsComponent,
    canActivate: [authGuard],
    data: { breadcrumbs: true, archived: true },
  },
  {
    path: 'my-schools/:id/jobs/create',
    component: JobCreateComponent,
    canActivate: [authGuard],
  },
  {
    path: 'my-schools/:id/jobs/:jobId/applicants',
    component: SchoolsJobsApplicantsComponent,
    canActivate: [authGuard],
    data: { breadcrumbs: true },
  },
  {
    path: 'my-schools/:id/edit/permissions',
    component: SchoolPermissionsFormComponent,
    canActivate: [authGuard],
  },
  //#endregion
  //#region jobs
  {
    path: 'jobs',
    component: JobsComponent,
  },
  {
    path: 'jobs/:id',
    component: JobDetailComponent,
  },
  {
    path: 'jobs/:id/edit/addInfos',
    component: JobAdditionalInfosFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/date',
    component: JobDateFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/duration',
    component: JobDurationFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/deadline',
    component: JobApplicationDeadlineFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/function',
    component: JobFunctionFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/requirements',
    component: JobRequirementsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/schoollevels',
    component: JobSchoolLevelsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/skills',
    component: JobSkillsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/subjects',
    component: JobSubjectsFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/tasks',
    component: JobTasksFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/weoffer',
    component: JobWeOfferFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/workload',
    component: JobWorkloadFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/apply',
    component: JobApplyComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/media',
    component: JobMediaFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/edit/contacts',
    component: JobContactFormComponent,
    canActivate: [authGuard],
  },
  {
    path: 'jobs/:id/apply/public',
    component: JobApplyPublicComponent,
  },
  //#endregion
  //#region application
  {
    path: 'applications/my',
    component: MyApplicationsComponent,
    data: { archived: false },
    canActivate: [authGuard],
  },
  {
    path: 'applications/my/archived',
    component: MyApplicationsComponent,
    data: { archived: true },
    canActivate: [authGuard],
  },
  {
    path: 'applications/:id',
    component: ApplicationDetailComponent,
    // TODO: only visible to school owner or applicant
    canActivate: [authGuard],
  },
  //#endregion
  //#region settings
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/account',
    component: SettingsAccountComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-email',
    component: SettingsChangeEmailComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-name',
    component: SettingsChangeNameComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-name/info',
    component: SettingsChangeNameInfoComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-password',
    component: SettingsChangePasswordComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-password/secure-password',
    component: SettingsChangePasswordSecureHelpComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/change-contact',
    component: SettingsContactComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/delete-account',
    component: SettingsDeleteAccountComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/marketing',
    component: SettingsMarketingComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings/privacy',
    component: SettingsPrivacyComponent,
    canActivate: [authGuard],
  },
  {
    path: 'search-agent',
    component: SearchAgentComponent,
    canActivate: [authGuard],
  },
  //#endregion
  {
    path: 'goodbye',
    component: GoodbyeComponent,
  },
  {
    path: 'auth-edulog-callback',
    component: EdulogCallbackComponent,
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('../modules/chat/chat.module').then(m => m.ChatModule),
    canActivate: [authGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
