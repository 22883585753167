@if (!showEmptyCard()) {
  <section class="contact-card">
    <header class="contact-card__header">
      <strong class="contact-card__header__title">Ansprechpersonen</strong>
      @if (isEditable()) {
        <app-edit-button routerLink="edit/contacts" />
      }
    </header>
    <main class="contact-card__content">
      @for (contact of contacts; track contact.userId) {
        <app-job-contact-card-item [contact]="contact" />
      }
    </main>
  </section>
}

@if (showEmptyCard()) {
  <app-empty-card
    title="Ansprechpersonen"
    subtitle="Kontaktdaten"
    description="Füge hier die Ansprechpersonen für dieses Stelleninserat hinzu."
    link="edit/contacts" />
}
