import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-bar-item',
  templateUrl: './top-bar-item.component.html',
  styleUrl: './top-bar-item.component.scss',
})
export class TopBarItemComponent {
  @Input({ required: true }) icon: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() iconTitle: string = '';
  @Output() action = new EventEmitter();

  handleClick() {
    this.action.emit();
  }
}
