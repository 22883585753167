<div class="w-full border-dot rounded-[0.75rem] md:rounded-[1.35rem] p-10">
  <div class="flex flex-col gap-8">
    <div>
      <h2 class="font-bold text-2xl">Gib deiner Schule ein Gesicht!</h2>
      <span class="text-sm font-very-bright-color">
        Ziel ist ein aussagekräftiges und authentisches Schulprofil.
      </span>
    </div>
    <div class="flex flex-col xl:flex-row gap-8">
      <div class="flex flex-row flex-wrap md:flex-nowrap gap-8">
        <div class="flex flex-col text-sm gap-2 w-full sm:w-auto">
          <strong class="text-base font-bold">Pflichtangaben</strong>
          <app-progress-card-item
            text="Schulart & -stufe*"
            link="edit/name"
            [success]="hasType" />
          <app-progress-card-item
            text="Steckbrief*"
            link="edit/info"
            [success]="hasInfo" />
          <app-progress-card-item
            text="Über uns*"
            link="edit/about"
            [success]="hasAbout" />
          <app-progress-card-item
            text="Ansprechpersonen*"
            [link]="permissionLink"
            [success]="hasContacts" />
        </div>
        <div class="flex flex-col text-sm gap-2 w-full sm:w-auto">
          <strong class="text-base font-bold">
            Hier geht's in die Tiefe
          </strong>
          <app-progress-card-item
            text="Video"
            scrollTargetId="intro-card"
            [success]="hasMedia" />
          <app-progress-card-item
            text="Impressionen"
            link="edit/media"
            [success]="hasMedia" />
          <app-progress-card-item
            text="Das bieten wir"
            link="edit/weoffer"
            [success]="hasWeOffer" />
          <app-progress-card-item
            text="Pädagogischer Fokus"
            link="edit/pedagogic-focus"
            [success]="hasPedagogicFocus" />
          <app-progress-card-item
            text="Schulentwicklung & -betrieb"
            link="edit/documents"
            [success]="hasDocuments" />
        </div>
      </div>
      <div class="rounded-2xl p-8 bg-box text-base">
        *Diese Mindestangaben sind notwendig, damit du das Schulprofil
        veröffentlichen kannst.
      </div>
    </div>
  </div>
</div>
