import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-bar-preview',
  templateUrl: './top-bar-preview.component.html',
  styleUrl: './top-bar-preview.component.scss',
})
export class TopBarPreviewComponent {
  @Input({ required: true }) disabled: boolean = false;
  @Output() preview = new EventEmitter();

  handlePreview() {
    this.preview.emit();
  }
}
