<button
  class="h-10 w-10 md:w-fit md:px-4 gap-2 rounded-full md:rounded-3xl flex justify-center items-center text-[#ffffff]"
  [disabled]="disabled || loading"
  (click)="handlePublish()"
  [ngClass]="{
    'bg-bn-primary-blue': !disabled && !loading,
    'bg-bn-blue-medium': disabled || loading
  }">
  @if (loading) {
    <mat-spinner class="m-[5px] white-spinner" diameter="30" />
  } @else {
    <mat-icon
      class="material-symbols-rounded font-bold md:text-[18px] md:h-[18px] md:w-[18px]">
      publish
    </mat-icon>
  }
  <div class="hidden md:block font-bold">Veröffentlichen</div>
</button>
