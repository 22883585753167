@if (!showEmptyCard) {
  <strong class="font-bold text-2xl md:text-4xl"> Werte </strong>
  @if (isOwner) {
    <app-edit-button
      class="pl-4"
      routerLink="edit/preferences"></app-edit-button>
  }
  <div class="flex flex-col pt-4 gap-8">
    <div class="flex gap-4 flex-wrap">
      @for (preference of profile.preferences; track preference) {
        <mat-chip
          class="font-normal optimize"
          [matTooltip]="preference"
          [aria-label]="preference">
          {{ preference }}
        </mat-chip>
      }
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Werte"
    subtitle="Mach dich interessanter!"
    description="Zeig was dir im täglichen Miteinander besonders wichtig ist."
    link="edit/preferences"></app-empty-card>
}
