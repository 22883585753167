<div class="flex justify-between">
  @for (day of days; track day; let i = $index) {
    <div class="flex flex-col items-center">
      <div class="hidden lg:block w-[90px] text-center">{{ getDay(i) }}</div>
      <div class="block lg:hidden">{{ getShortDay(i) }}</div>
      <mat-checkbox
        color="primary"
        [disabled]="disabled"
        (click)="select($event, day, 'am')"
        [checked]="!!day.am"
        [ngClass]="{
          'checkbox-fulltime': isFullTimeJob,
          'checkbox-substitution': !isFullTimeJob
        }"></mat-checkbox>
      <mat-checkbox
        color="primary"
        [disabled]="disabled"
        (click)="select($event, day, 'pm')"
        [checked]="!!day.pm"
        [ngClass]="{
          'checkbox-fulltime': isFullTimeJob,
          'checkbox-substitution': !isFullTimeJob
        }"></mat-checkbox>
    </div>
  }
  <div class="flex flex-col gap-4 pt-8">
    <div>Vormittag</div>
    <div class="pt-1">Nachmittag</div>
  </div>
</div>
