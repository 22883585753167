import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-bar-publish',
  templateUrl: './top-bar-publish.component.html',
  styleUrl: './top-bar-publish.component.scss',
})
export class TopBarPublishComponent {
  @Input({ required: true }) disabled: boolean = false;
  @Input({ required: true }) loading: boolean = false;
  @Output() publish = new EventEmitter();

  handlePublish() {
    this.publish.emit();
  }
}
