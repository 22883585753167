<app-top-bar-item
  class="md:hidden"
  icon="preview"
  [disabled]="disabled"
  iconTitle="Vorschau"
  (action)="handlePreview()" />
<button
  mat-stroked-button
  class="hidden md:flex gap-2 action-button"
  color="primary"
  [disabled]="disabled"
  (click)="handlePreview()">
  <mat-icon class="material-symbols-rounded font-bold mr-0 ml-[2px]">
    preview
  </mat-icon>
  <span class="inline-block">Vorschau</span>
</button>
