@if (!showEmptyCard) {
  <div
    class="flex flex-col justify-between p-5 md:p-10 text-base border-2 border-bn-grey-border rounded-2xl">
    <div class="flex justify-between gap-5">
      <strong
        class="font-bold text-2xl md:text-4xl overflow-visible whitespace-nowrap">
        {{ getTitle() }}
      </strong>
      @if (isEditable) {
        <app-edit-button [routerLink]="permissionLink" />
      }
    </div>
    <div class="flex flex-col pt-4 gap-8 w-full">
      <div
        class="flex gap-y-5 flex-col md:flex-row md:flex-wrap justify-between w-full">
        @for (contact of school.contacts; track contact.userId) {
          <div
            class="flex justify-between md:justify-start md:gap-10 w-full max-w-[420px]">
            <a [routerLink]="['/profile', contact.userId]">
              <div class="flex md:flex-1 gap-3 md:gap-5">
                <div
                  class="flex grow image-container rounded-full min-h-[60px] min-w-[60px] h-[60px] w-[60px] md:h-[80px] md:w-[80px] md:min-h-[80px] md:min-w-[80px]"
                  [ngStyle]="{
                    'background-image':
                      'url(' +
                      getImageUrl(contact.userId) +
                      '), url(' +
                      defaultSrc +
                      ')'
                  }"
                  [ngClass]="{
                    'image-container__empty': !getImageUrl(contact.userId)
                  }"></div>
                <div class="flex flex-col">
                  <div
                    class="font-bold line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                    {{ contact.name }}
                  </div>
                  @if (contact.jobFunction) {
                    <div
                      class="line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                      {{ contact.jobFunction }}
                    </div>
                  }
                  @if (contact.phone) {
                    <div
                      class="line-clamp-1 break-all text-ellipsis text-sm md:text-base">
                      {{ contact.phone }}
                    </div>
                  }
                </div>
              </div>
            </a>
            @if (isAuthorized$ | async) {
              <button
                mat-icon-button
                class="fab-button-color scale-[0.85] cursor-pointer self-center"
                (click)="openChat(contact.userId)">
                <mat-icon class="material-symbols-rounded scale-[1.2]">
                  forum
                </mat-icon>
              </button>
            } @else {
              <a
                [href]="'mailto:' + contact.email"
                mat-icon-button
                class="fab-button-color scale-[0.85] cursor-pointer self-center">
                <mat-icon class="material-symbols-rounded scale-[1.2]">
                  email
                </mat-icon>
              </a>
            }
          </div>
        }
      </div>
    </div>
  </div>
}

@if (showEmptyCard && isEditable) {
  <app-empty-card
    title="Ansprechpersonen"
    subtitle="Kontaktdaten verwalten"
    description="Füge hier die schulischen Ansprechpersonen hinzu."
    [link]="permissionLink" />
}
