import { Component, computed, inject, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { School } from '../../../../types/school';
import { environment } from '../../../../../environments/environment';
import {
  MediaVideoService,
  MediaVideoServiceActions,
} from '../../../../services/media-video.service';

@Component({
  selector: 'app-school-intro-card',
  templateUrl: './school-intro-card.component.html',
  styleUrls: ['./school-intro-card.component.scss'],
})
export class SchoolIntroCardComponent {
  readonly school = model.required<School>();
  readonly isEditable = input.required<boolean>();
  readonly showEmptyCard = computed(() => !this.school().video?.id);

  readonly mediaVideoService = inject(MediaVideoService);
  readonly dialog = inject(MatDialog);

  loading: boolean = false;
  buttonText: string = 'Hochladen';

  constructor() {
    this.mediaVideoService.uploadProgress$.subscribe(progress => {
      if (this.loading) {
        this.buttonText = this.mediaVideoService.getProgress(progress);
      }
    });
  }

  async upload(event: Event): Promise<void> {
    const sid = this.school().id;

    if (sid) {
      this.loading = true;
      const video = await this.mediaVideoService.upload(
        event,
        sid,
        MediaVideoServiceActions.SCHOOL
      );
      this.school.update(school => ({ ...school, video }));
      this.loading = false;
    }
  }

  async remove(): Promise<void> {
    if (environment.disableVideoDeletion) {
      alert('Video deletion is disabled');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.mediaVideoService.data,
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      const school = this.school();

      if (confirmed && school.id && school.video?.id) {
        this.loading = true;
        this.buttonText = this.mediaVideoService.deleting;
        await this.mediaVideoService.remove(
          school.video.id,
          school.id,
          MediaVideoServiceActions.SCHOOL
        );
        this.school.update(school => ({ ...school, video: null }));
        this.buttonText = 'Hochladen';
        this.loading = false;
      }
    });
  }
}
