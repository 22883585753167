<section class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">
        {{ data().isCover ? 'Audio-Titelbild' : 'Foto' }}
      </h1>
      <span class="text-base text-[#B5B6C1]">
        @if (data().isCover) {
          Wähle hier deinen passenden Bildausschnitt für das Titelbild, indem du
          das Rechteck adjustierst und die Zoomfunktion verwendest.
        } @else {
          Wähle hier deinen passenden Bildausschnitt, indem du das Rechteck
          adjustierst und die Zoomfunktion verwendest.
        }
      </span>
    </div>
    <div class="flex flex-col gap-8">
      <div
        class="flex justify-center items-center min-w-full h-auto relative aspect-video">
        <image-cropper
          alignImage="center"
          format="jpeg"
          backgroundColor="#fff"
          class="image-cropper"
          [class.hidden]="loading"
          [imageBase64]="base64Image"
          [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9"
          [imageQuality]="100"
          [onlyScaleDown]="true"
          [resizeToWidth]="dimension.width"
          [resizeToHeight]="dimension.height"
          [containWithinAspectRatio]="true"
          [allowMoveImage]="true"
          [(transform)]="transform"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="loading = false" />
        @if (loading) {
          <mat-spinner [diameter]="30" />
        }
      </div>
      <div class="flex flex-col xl:flex-row gap-4 items-center justify-end">
        @if (!loading) {
          <div class="flex">
            <button mat-icon-button color="grey" (click)="zoomOut()">
              <mat-icon class="material-symbols-rounded"> zoom_out </mat-icon>
            </button>
            <button mat-icon-button color="grey" (click)="zoomIn()">
              <mat-icon class="material-symbols-rounded"> zoom_in </mat-icon>
            </button>
          </div>
        }
      </div>

      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Achte auf eine gute Qualität der Fotos. Unterstützt werden JPG, JPEG
          und PNG.
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <button mat-button color="primary" (click)="cropped.emit(null)">
        Abbrechen
      </button>
      <app-action-button
        [disabled]="loading"
        (onClick)="save()"
        text="Speichern" />
    </div>
  </div>
</section>
