@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div class="flex w-fit">
      <strong class="font-bold text-2xl md:text-4xl">Tätigkeiten</strong>
      @if (isEditable) {
        <app-edit-button class="pl-4" routerLink="edit/tasks" />
      }
    </div>
    <app-rich-text-view
      [html]="job.yourTasks_html"
      tailwindCssLineClamp="line-clamp-[10]" />
  </div>
}

@if (showEmptyCard) {
  <app-empty-card
    title="Tätigkeiten"
    subtitle="Das erwartet deine künftigen Mitarbeitenden."
    description="Gehe bei der Aufgabenbeschreibung ruhig ins Detail, da Bewerbende genau wissen möchten, was sie erwartet."
    link="edit/tasks" />
}
