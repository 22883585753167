import { Component, computed, input, model } from '@angular/core';
import { Job, JobContact } from '../../../../types/job';

@Component({
  selector: 'app-job-contact-card',
  templateUrl: './job-contact-card.component.html',
  styleUrl: './job-contact-card.component.scss',
})
export class JobContactCardComponent {
  readonly job = model.required<Job>();
  readonly isEditable = input.required<boolean>();
  readonly showEmptyCard = computed(
    () => !this.job().contacts?.filter(contact => !!contact.visible).length
  );

  get contacts(): JobContact[] {
    return this.job().contacts?.filter(contact => !!contact.visible) ?? [];
  }
}
