<button
  class="h-10 w-10 rounded-full flex justify-center items-center"
  [disabled]="disabled"
  (click)="handleClick()">
  @if (loading) {
    <mat-spinner class="m-[5px]" diameter="30"></mat-spinner>
  } @else {
    <mat-icon
      class="material-symbols-rounded font-bold"
      [ngClass]="{ 'bright-font': disabled }"
      [attr.title]="iconTitle">
      {{ icon }}
    </mat-icon>
  }
</button>
