<button
  mat-flat-button
  color="primary"
  class="sm:flex hidden w-[206px] items-center"
  (click)="onShare()">
  <mat-icon class="material-symbols-rounded">share</mat-icon>
  <span>Schulprofil teilen</span>
</button>

<button
  class="sm:hidden bg-bn-primary-blue text-white rounded-full flex justify-center items-center hover:brightness-[95%] aspect-square h-10 w-10"
  matRipple
  (click)="onShare()"
  aria-label="teilen">
  <mat-icon class="material-symbols-rounded">share</mat-icon>
</button>
